<template>
  <div :class="'iq-sidebar sidebar-default ' + variant" v-if="this.$store.getters.usuario.role == 1">

    <div class="iq-sidebar-logo d-flex align-items-end justify-content-between"
         v-if="this.$store.getters.usuario.role == 1">
      <a href="#" class="header-logo">
        <img src="@/assets/images/Ayuda.png" class="img-fluid rounded-normal light-logo" alt="logo">
        <img src="@/assets/images/logo-dark.png" class="img-fluid rounded-normal d-none sidebar-light-img" alt="logo">
        <span>Avanza</span>
      </a>
      <div class="side-menu-bt-sidebar-1">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-light wrapper-menu" width="30" fill="none"
             viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </div>
    </div>
    <!--  <div class="data-scrollbar" data-scroll="1" id="bannerExamen" v-if="this.$store.getters.usuario.role == 1" >aqui
        <nav class="iq-sidebar-menu">
          <ul id="bienvenido_card" class="side-menu" >
            <li class="text-center">
                <span class="text-uppercase font-weight-bold" style="font-size: 20px;">
                  Bienvenid@
                </span>
            </li>
          </ul>
        </nav>
      </div>-->
    <div class="data-scrollbar" data-scroll="1" id="sidebar-scrollbar" v-if="this.$store.getters.usuario.role == 1">
      <nav class="iq-sidebar-menu" v-if="this.$store.getters.usuario.role == 1">
        <ul id="iq-sidebar-toggle" class="side-menu">
          <li class="text-center"
              v-if="this.$store.getters.usuario.role != 1 || this.$store.getters.usuario.role != 10">
          </li>
          <li class="px-3 pt-3 pb-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-building-fill-gear" viewBox="0 0 16 16">
              <path
                  d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v4h10V2a1 1 0 0 0-1-1H4zm9 6H6v2h7V7zm0 3H6v2h7v-2zm0 3H6v2h6a1 1 0 0 0 1-1v-1zm-8 2v-2H3v1a1 1 0 0 0 1 1h1zm-2-3h2v-2H3v2zm0-3h2V7H3v2z"/>
            </svg>
            <span class="text-uppercase small font-weight-bold">EXÁMENES</span>
          </li>
          <li :class="( checkActive('dashboardExamenes') ) ? 'sidebar-layout active' : 'sidebar-layout'"
              v-if="this.$store.getters.usuario.role == '1' || this.$store.getters.usuario.role === 10">
            <router-link :to="{name: 'dashboardExamenes'}" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2"
                     viewBox="0 0 16 16">
                  <path
                      d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                  <path
                      d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                </svg>
              </i>
              <span class="ml-2">Dashboard</span>
            </router-link>
          </li>
          <li :class="( checkActive('crearExamen') ) ? 'sidebar-layout active' : 'sidebar-layout'"
              v-if="this.$store.getters.usuario.role === 1 || this.$store.getters.usuario.role === 10">
            <router-link :to="{name: 'crearExamen'}" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2"
                     viewBox="0 0 16 16">
                  <path
                      d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z"/>
                </svg>
              </i>
              <span class="ml-2">Crear Examen</span>
            </router-link>
          </li>

          <li class="px-3 pt-3 pb-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-building-fill-gear" viewBox="0 0 16 16">
              <path
                  d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7.256A4.493 4.493 0 0 0 12.5 8a4.493 4.493 0 0 0-3.59 1.787A.498.498 0 0 0 9 9.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .39-.187A4.476 4.476 0 0 0 8.027 12H6.5a.5.5 0 0 0-.5.5V16H3a1 1 0 0 1-1-1V1Zm2 1.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5Zm3 0v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"/>
              <path
                  d="M11.886 9.46c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"/>
            </svg>
            <span class="text-uppercase small font-weight-bold">

                      REACTIVOS</span>
          </li>


          <li :class="( checkActive('dashboardPreguntas') ) ? 'sidebar-layout active' : 'sidebar-layout'"
              v-if="this.$store.getters.usuario.role === 1 || this.$store.getters.usuario.role === 10">
            <router-link :to="{name: 'dashboardPreguntas'}" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2"
                     viewBox="0 0 16 16">
                  <path
                      d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                  <path
                      d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                </svg>
              </i>
              <span class="ml-2">Dashboard</span>
            </router-link>
          </li>


          <li :class="( checkActive('opcionMultipleUnaRespuesta') ) ? 'sidebar-layout active' : 'sidebar-layout'"
              v-if="this.$store.getters.usuario.role === 1 || this.$store.getters.usuario.role === 10">
            <router-link :to="{name: 'opcionMultipleUnaRespuesta'}" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2"
                     viewBox="0 0 16 16">
                  <path
                      d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                </svg>
              </i>
              <span class="ml-2">única respuesta</span>
            </router-link>
          </li>

          <li :class="( checkActive('reactivoVariasRespuestas') ) ? 'sidebar-layout active' : 'sidebar-layout'"
              v-if="this.$store.getters.usuario.role === 1 || this.$store.getters.usuario.role === 10">
            <router-link :to="{name: 'reactivoVariasRespuestas'}" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-check2-all" viewBox="0 0 16 16">
                  <path
                      d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"/>
                  <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"/>
                </svg>
              </i>
              <span class="ml-2">varias respuestas</span>
            </router-link>
          </li>

          <li :class="( checkActive('reactivoRelacionarColumnas') ) ? 'sidebar-layout active' : 'sidebar-layout'"
              v-if="this.$store.getters.usuario.role === 1 || this.$store.getters.usuario.role === 10">
            <router-link :to="{name: 'reactivoRelacionarColumnas'}" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-check2-all" viewBox="0 0 16 16">
                  <path
                      d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                </svg>
              </i>
              <span class="ml-2">Relacionar columnas</span>
            </router-link>
          </li>


          <li :class="( checkActive('ordenamiento') ) ? 'sidebar-layout active' : 'sidebar-layout'"
              v-if="this.$store.getters.usuario.role === 1 || this.$store.getters.usuario.role === 10">
            <router-link :to="{name: 'ordenamiento'}" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-check2-all" viewBox="0 0 16 16">
                  <path
                      d="M6 1H1v14h5V1zm9 0h-5v5h5V1zm0 9v5h-5v-5h5zM0 1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm9 0a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1V1zm1 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-5z"/>
                </svg>
              </i>
              <span class="ml-2">Ordenamiento</span>
            </router-link>
          </li>

          <li :class="( checkActive('reactivoCategorizacion') ) ? 'sidebar-layout active' : 'sidebar-layout'"
              v-if="this.$store.getters.usuario.role === 1 || this.$store.getters.usuario.role === 10">
            <router-link :to="{name: 'reactivoCategorizacion'}" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-check2-all" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>
                </svg>
              </i>
              <span class="ml-2">Categorización</span>
            </router-link>
          </li>

          <li :class="( checkActive('reactivoVerdaderoFalso') ) ? 'sidebar-layout active' : 'sidebar-layout'"
              v-if="this.$store.getters.usuario.role === 1 || this.$store.getters.usuario.role === 10">
            <router-link :to="{name: 'reactivoVerdaderoFalso'}" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-check2-all" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                </svg>
              </i>
              <span class="ml-2">Verdadero/Falso</span>
            </router-link>
          </li>


          <li class="px-3 pt-3 pb-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-building-fill-gear" viewBox="0 0 16 16">
              <path
                  d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7.256A4.493 4.493 0 0 0 12.5 8a4.493 4.493 0 0 0-3.59 1.787A.498.498 0 0 0 9 9.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .39-.187A4.476 4.476 0 0 0 8.027 12H6.5a.5.5 0 0 0-.5.5V16H3a1 1 0 0 1-1-1V1Zm2 1.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5Zm3 0v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z"/>
              <path
                  d="M11.886 9.46c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"/>
            </svg>
            <span class="text-uppercase small font-weight-bold">

                      Asignación</span>
          </li>
          <li :class="( checkActive('dashboardAsignaciones') ) ? 'sidebar-layout active' : 'sidebar-layout'"
              v-if="this.$store.getters.usuario.role === 1 || this.$store.getters.usuario.role === 10">
            <router-link :to="{name: 'dashboardAsignaciones'}" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2"
                     viewBox="0 0 16 16">
                  <path
                      d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                  <path
                      d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                </svg>
              </i>
              <span class="ml-2">Dashboard</span>
            </router-link>
          </li>
          <li :class="( checkActive('dashboardGrupos') ) ? 'sidebar-layout active' : 'sidebar-layout'"
              v-if="this.$store.getters.usuario.role === 1 || this.$store.getters.usuario.role === 10">
            <router-link :to="{name: 'dashboardGrupos'}" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2"
                     viewBox="0 0 16 16">
                  <path
                      d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                  <path
                      d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                </svg>
              </i>
              <span class="ml-2">Grupos</span>
            </router-link>
          </li>
          <li :class="( checkActive('dashboardImportaciones') ) ? 'sidebar-layout active' : 'sidebar-layout'"
              v-if="this.$store.getters.usuario.role === 1 || this.$store.getters.usuario.role === 10">
            <router-link :to="{name: 'dashboardImportaciones'}" class="svg-icon">
              <i class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2"
                     viewBox="0 0 16 16">
                  <path
                      d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                  <path
                      d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z"/>
                </svg>
              </i>
              <span class="ml-2">Importaciones</span>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>


  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {core} from '@/config/pluginInit'
//import reactivosOrdenamiento from "@/views/PoC/reactivos-ordenamiento.vue";
export default {
  name: "SidebarStyle",
  props: {
    items: Array,
    variant: {type: String}
  },
  data() {
    return {
      homeurl: '',
      dashboards: ['layout.dashboard1', 'layout.dashboard2', 'layout.dashboard3', 'layout.customer', 'layout.product', 'layout.order', 'layout.calendar'],
      app: ['app.chat', 'app.todo'],
      charts: ['chart.amchart', 'chart.apexchart', 'chart.highchart', 'chart.morrischart',],
      user: ['app.user-profile', 'app.user-add', 'app.user-list'],
      ui: ['Ui.avatars', 'Ui.alerts', 'Ui.badges', 'Ui.breadcrumbs', 'Ui.buttons', 'Ui.button-groups', 'Ui.boxshadows', 'Ui.colors', 'Ui.cards', 'Ui.carousels', 'Ui.grids', 'Ui.helper-classes', 'Ui.images', 'Ui.list-groups', 'Ui.media-objects', 'Ui.modals', 'Ui.notifications'
        , 'Ui.paginations', 'Ui.popovers', 'Ui.progressbars', 'Ui.typographys', 'Ui.tabs', 'Ui.tooltips', 'Ui.Embed-videos'],
      auth: ['auth.login', 'auth.register', 'auth.recover-password', 'auth.confirm-mail', 'auth.lock-screen'],
      pricing: ['price.pay', 'price.pay2'],
      icon: ['icon.dripicon', 'icon.fontawsome', 'icon.lineawsome', 'icon.remixicon',],
      error: ['error.404', 'error.500'],
      formControls: ['controls.form-checkbox', 'controls.form-layout', 'controls.form-input', 'controls.form-radio', 'controls.form-switch', 'controls.form-textarea', 'controls.form-validation',],
      formWidget: ['widget.form-datepicker', 'widget.form-file-uploader', 'widget.form-quill',],
      table: ['table.basic-table', 'table.data-table', 'table.edit-table',],
      timeline: ['time.line', 'time.line1'],
      extraPages: ['price.pay', 'pages.maintenance', 'pages.comingsoon', 'pages.invoices', 'pages.subscribers', 'pages.faq', 'pages.blank-page', 'pages.icon'],
      pages: []
    }
  },
  mounted() {

    core.SmoothScrollbar()
    const urlParams = new URLSearchParams(window.location.search);
    const sidebar = urlParams.get('sidebar');
    if (sidebar !== null) {
      this.variant = ''
      switch (sidebar) {
        case "0":
          this.variant = 'sidebar-dark'
          break;
        case "1":
          this.variant = 'sidebar-light'
          break;
        default:
          this.variant = ''
          break;
      }
    }

  },
  destroyed() {
    core.SmoothScrollbar()
  },
  computed: {
    /*      reactivosOrdenamiento() {
            return reactivosOrdenamiento
          }*/
    ...mapGetters({
      logo: 'darklogo',
      sidelogo: 'logo',
    })
  },
  methods: {
    checkActive(route) {
      if (this.$route.name == route) {
        return true;
      }
      if (route.includes(this.$route.name)) {
        return true
      }
    },
    miniSidebar() {
      core.triggerSet()
    },
    showCollapse(collapseId) {
      document.getElementById(collapseId).closest('li').classList.add('active')
    },
    hideCollapse() {
      const list = document.querySelector('.iq-sidebar-menu').querySelectorAll('.submenu')
      Array.from(list, elem => {
        if (elem.closest('.active') !== null && elem.closest('.active') !== undefined) {
          elem.closest('.active').classList.remove('active')
        }
      })
    }
  },
}
</script>

