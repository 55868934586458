<template>
  <div style="background-color:#FAFAFA !important">
    <!--    <Loader/> loader que sirve-->
    <div id="admin" v-if="this.$store.getters.usuario.role == 1">
      <div class="wrapper">
        <SidebarStyle  variant='' />
        <HeaderStyle1/>
        <div class="content-page">
          <transition name="router-anim">
            <router-view/>
          </transition>
        </div>
      </div>
      <FooterStyle />
    </div>

    <div id="alumno" v-if="this.$store.getters.usuario.role == 4">

      <HeaderStyleAlumno/>
<!--          <div class="content-page">
            <transition name="router-anim">-->
              <router-view/>
<!--            </transition>
          </div>-->
    </div>
  </div>
<!--
  <div id="alumno" style="background-color:#FAFAFA !important" v-if="this.$store.getters.usuario.role == 4">
    &lt;!&ndash;    <Loader/> loader que sirve&ndash;&gt;
    <div class="wrapper">
      {{this.$store.getters.usuario.role}}
      <SidebarStyle  variant='' />
      <HeaderStyle1/>
      <div class="content-page">
        <transition name="router-anim">
          <router-view/>
        </transition>
      </div>
    </div>
    <FooterStyle />
  </div>-->
</template>
<script>
//import Loader from '@/components/loader/Loader'
import SidebarStyle from "@/components/partials/backend/SidebarStyle/SidebarStyle";
import HeaderStyle1 from "@/components/partials/backend/HeaderStyle/HeaderStyle1";
import HeaderStyleAlumno from "@/components/partials/backend/HeaderStyle/HeaderStyleAlumno";
import FooterStyle from "@/components/partials/backend/FooterStyle/FooterStyle";
export default {
  name: 'Layout-2',
  components: {
        HeaderStyle1,
        HeaderStyleAlumno,
        FooterStyle,
        SidebarStyle,
        //Loader
  }, 
    beforeCreate() {
        this.$store.dispatch("fetchUsuario");
  },
  
}
</script>
<style>
</style>
