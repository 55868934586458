<template>
   <footer class="iq-footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6">
<!--                <ul class="list-inline mb-0">
                    <li class="list-inline-item"><router-link :to="{name: 'app.privacypolicy'}"> <small> Política de privacidad</small></router-link></li>
                    <li class="list-inline-item"><router-link :to="{name: 'app.Termsofuse'}"> <small> Terminos de uso</small></router-link></li>
                </ul>-->
            </div>
            <div class="col-lg-6 text-right">
               <span class="white--text">
                    <small> <a href="https://www.nive.la" target="blank" class="white--text"
                    > 
                    Nivel A  
                    </a>
                    &copy; {{ new Date().getFullYear() }} </small>
                </span>
            </div>
        </div>
    </div>
</footer>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name:"Footer",
    prop:{
    },
    
    computed : {
        ...mapGetters({
            appName: 'appName'
        })
    },

}
</script>