<template>
<!-- <div class="iq-top-navbar" >-->
<div class="active-class" >
    <div class="iq-navbar-custom">

      <nav class="navbar navbar-expand-lg navbar-light p-0" >
        <div class="side-menu-bt-sidebar" style="visibility: hidden">
          <svg    xmlns="http://www.w3.org/2000/svg"
                  class="text-secondary wrapper-menu"
                  width="30"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  @click="miniSidebar">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </div>

        <b-row class="text-center" v-if="this.$store.getters.usuario.role!=1">
          <b-col cols="12">
            <h2><span style="color: #000000"> {{this.$store.getters.usuario.nombre_examen}}</span></h2>

          </b-col>
        </b-row>
        <div class="d-flex align-items-center">

          <!--				<ModeSwitch />-->
          <b-navbar-toggle target="nav-collapse" class="">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav v-if="this.$store.getters.usuario.usuario_nivelando == 0">
            <ul class="navbar-nav ml-auto navbar-list align-items-center">


              <li class="nav-item nav-icon search-content" v-nav-toggle>
                <a href="#" class="search-toggle rounded dropdown-toggle" id="dropdownSearch" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg class="svg-icon text-secondary" id="h-suns" height="25" width="25" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </a>
                <div class="iq-search-bar iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownSearch">
                  <form action="#" class="searchbox p-2">
                    <div class="form-group mb-0 position-relative">
                      <input type="text" class="text search-input font-size-12" placeholder="type here to search...">
                      <a href="#" class="search-link">
                        <svg xmlns="http://www.w3.org/2000/svg" class="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                      </a>
                    </div>
                  </form>
                </div>
              </li>
              <li class="nav-item nav-icon dropdown" v-nav-toggle>
                <a href="#" class="nav-item nav-icon dropdown-toggle pr-0 search-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  <b-img
                      class="img-fluid avatar-rounded"
                      fluid
                      :src ="this.$store.getters.usuario.avatar"
                  ></b-img>
                  <span class="mb-0 ml-2 user-name">
                                    {{this.$store.getters.usuario.name}}
                                </span>
                </a>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" v-if="this.$store.getters.usuario.usuario_nivelando == 0">
                  <!--
 <li class="dropdown-item d-flex svg-icon">
                     <svg class="svg-icon mr-0 text-secondary" id="h-01-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                         <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                     </svg>
                     <router-link :to="{name: 'app.user-profile'}">My Profile</router-link>
                 </li>
                 <li class="dropdown-item d-flex svg-icon">
                     <svg class="svg-icon mr-0 text-secondary" id="h-02-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                         <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                     </svg>
                     <router-link :to="{name: 'app.user-profile-edit'}">Edit Profile</router-link>
                 </li>
 -->
                  <li class="dropdown-item  d-flex svg-icon border-top" v-if="this.$store.getters.usuario.usuario_nivelando == 0">
                    <svg class="svg-icon mr-0 text-secondary" id="h-05-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                    <b-button variant="link" @click="logout" >Salir</b-button>
                  </li>
                </ul>
              </li>
            </ul>
          </b-collapse>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import {core} from '@/config/pluginInit';
import { mapGetters } from 'vuex';
import ExamenService from '@/services/ExamenService';
//import axios from "axios";
export default {
  name:"HeaderStyle1",
  computed : {
    ...mapGetters({
      logo:'darklogo',
      sidelogo:'logo',
    })
  },

  methods: {
    async verificarTiempoExamen()
    {
      //this.$variableGlobal = 'aui lo edito';
      this.miGlobal = this.$variableGlobal;
      const response = await ExamenService.getverificarTiempoExamen(this.uuid_examen);
      this.$root.contador = response.contador;
      this.$root.estatusExamen = response.estatusExamen;

    },
    miniSidebar(){
      core.triggerSet()
    },
    logout() {
/*      window.location =
          "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=" +
          process.env.VUE_APP_API_URL +
          "/redirect";*/
     // window.location =process.env.VUE_APP_API_URL+"/logout";
/*      this.$api.pos("/logout").then(() => {
              window.location =
                "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=" +
                process.env.VUE_APP_API_URL +
                "/redirect";
            });*/

/*      this.$http.post('/logout', {
        'idExamen': this.idExamen
      }).then(() => {
        window.location =
            "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=" +
            process.env.VUE_APP_API_URL +
            "/redirect";
      }).catch(function (error) {
        console.log(error)
      })*/
/*      axios.post('http://localhost:8000/logout').then(() => {
        // Una vez que se haya establecido la cookie CSRF, hacer la solicitud para generar el token
        //console.log(response)
        window.location =
            "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=" +
            process.env.VUE_APP_API_URL +
            "/redirect";

      });*/
      this.$api.post('/logout')
          .then(() => {
            // Realizar cualquier lógica necesaria después de cerrar sesión en el frontend
            // Por ejemplo, limpiar el estado de autenticación, redirigir, etc.
            window.location =
                "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=" +
                process.env.VUE_APP_API_URL +
                "/redirect";
          })
          .catch(error => {
            console.error('Error al cerrar sesión:', error);
          });

/*      this.$api.get("/api/user").then((response) => {
        this.email = response.data.email;
        this.avatar = response.data.avatar;
      });*/
    },//logout
    verificarTiempo(){

    }
  },
  data(){
    return{
      contador:0,
      estatusExamen:0,
      uuid_examen:"eb1752d0-6dee-4522-86f4-18a5d6ae20c1",
      imageMainProps: {  width: 250, height: 250 },
      extraPages: ['app.user-Account-setting','app.user-privacy-setting'],
      auth: ['auth.login'],
      user: ['app.user-profile','app.user-profile-edit'],
      miGlobal : null
    }
  },
  mounted()
  {

  },
  beforeDestroy() {
    //clearInterval(this.intervalID);
  }


}
</script>

<style>
.active-class {
  background: #fff;
  padding: 0 30px 0 30px;
  min-height: 73px;
  /* position: fixed; */
  top: 0;
  left: auto;
  right: 0;
  /* width: calc(100% - 260px); */
  /* display: inline-block; */
  z-index: 99;
  margin: 0;
  transition: all 0.3s ease-in-out;
  -ms-box-shadow: 0px 0px 30px rgba(149, 47, 129, 0.05);
  -o-box-shadow: 0px 0px 30px rgba(149, 47, 129, 0.05);
  box-shadow: 0px 0px 30px rgba(149, 47, 129, 0.05);
}

.active-class .navbar-expand-lg {
  justify-content: space-between !important;
}

</style>
